import React from "react"
import Helmet from "react-helmet"
import "../styles/global.css"
import Navbar from "./navbar"
import Sidebar from "./sidebar"
import Footer from "./footer"
import disableScroll from "disable-scroll"
import { RiWhatsappFill } from "react-icons/ri"
import Favicon from "../images/logo-black.png"

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      disableScroll.on()
    }
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href={Favicon} />
      </Helmet>
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      {children}
      <a
        href="https://wa.me/351914644724"
        className="whatsapp_float animate-pulse "
        target="_blank"
        rel="noopener noreferrer"
      >
        <RiWhatsappFill className=" whatsapp-icon" />
      </a>
      <Footer />
    </>
  )
}

export default Layout
