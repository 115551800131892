import React from "react";
import Logo from "../images/logo-black.png";
import { AiOutlineFacebook, AiOutlineInstagram } from "react-icons/ai";
import { FaTripadvisor } from "react-icons/fa";
import GoogleMaps from "./googlemaps";
import { Link } from "gatsby";

const footer = () => {
  return (
    <>
      {" "}
      <footer className="flex gap-3 w-full h-56 mt-9 px-3">
        <div className="w-1/3 flex flex-col justify-center items-center text-xs">
          <Link to="/">
            <img
              src={Logo}
              alt="Restaurante Monte Alegre"
              className="w-14 h-16"
            />
          </Link>
          <h6 className="text-xs text-center w-4/5 mb-1 lg:text-lg">
            Restaurante Monte Alegre
          </h6>
          <p className=" text-xs w-36 p-2 lg:w-96 lg:text-sm text-center mb-2 ">
            Venha comer comida tradicional Portuguesa, temos menu de carne,
            peixe e fantásticas sobremesas.
          </p>
          <hr className="border-1 w-3/4 mb-2" />
          <a href="tel:351253098320">
            <span className="text-red-700 text-small mb-1 font-medium lg:text-lg">
              +351 253 098 320{" "}
              <span className="text-xs pl-2">*Rede Fixa Nacional</span>
            </span>
          </a>
          <div className="text-yellow-600 flex gap-2 text-xl lg:text-3xl">
            <a href="https://www.facebook.com/restaurantemontealegre1">
              <AiOutlineFacebook className="text-md" />
            </a>
            <a href="https://www.instagram.com/restmontealegreehotmail.com_/">
              <AiOutlineInstagram className="text-md" />
            </a>
            <a href="https://www.tripadvisor.pt/Restaurant_Review-g189174-d12278383-Reviews-Restaurante_Monte_Alegre-Guimaraes_Braga_District_Northern_Portugal.html">
              <FaTripadvisor className="text-md" />
            </a>
          </div>
        </div>
        <div className=" w-5/6">
          {" "}
          <GoogleMaps />
        </div>
      </footer>
      <div className="flex flex-col justify-center items-center gap-1 mt-5">
        <hr className="w-4/5 h-0.5 " />
        <div className="flex">
          <span className="text-xs">Copyright©2021 |</span>
          <a
            href="https://developedbycarlos.com"
            className="text-xs text-yellow-700 font-medium ml-1"
          >
            DevelopedbyCarlos
          </a>
        </div>
      </div>
    </>
  );
};

export default footer;
