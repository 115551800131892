import React from "react";

const googlemaps = () => {
  return (
    <div>
      <iframe
        title="google-maps"
        className="w-full h-52"
        id="gmap_canvas"
        src="https://maps.google.com/maps?q=Av.%20Padre%20Jo%C3%A3o%20Fernandes%20Machado%20230,%204800-090%20&t=k&z=19&ie=UTF8&iwloc=&output=embed"
      ></iframe>
    </div>
  );
};

export default googlemaps;
