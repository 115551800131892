import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
const query = graphql`
  {
    site {
      siteMetadata {
        author
        contact
        siteDesc: description
        siteUrl
        siteTitle: title
        words: keywords
        image
      }
    }
  }
`;

const SEO = ({ title, description, keywords }) => {
  const { site } = useStaticQuery(query);
  const { siteDesc, siteTitle, words, image } = site.siteMetadata;

  return (
    <Helmet title={`${title} | ${siteTitle} `} htmlAttributes={{ lang: "pt" }}>
      <meta name="description" content={description || siteDesc} />
      <meta name="keywords" content={keywords || words} />
      <meta name="image" content={image} />
      <meta name="author" content="@Carlos_webdev" />
    </Helmet>
  );
};

export default SEO;
