import React from "react"
import { Link } from "gatsby"
import { MdClose } from "react-icons/md"
import "../styles/global.css"
import disableScroll from "disable-scroll"
import { FaArrowAltCircleRight } from "react-icons/fa"

const sidebar = ({ isOpen, toggleSidebar }) => {
  if (!isOpen) {
    disableScroll.off()
  }
  return (
    <aside className={`${"sidebar"} ${isOpen ? "showSidebar" : ""}`}>
      <button onClick={toggleSidebar} className="closeBtn ">
        <MdClose className="hover:text-yellow-600" />
      </button>
      <div className="flex flex-col gap-9 justify-center items-center">
        <Link
          className="hover:text-red-700  font-medium"
          to="/"
          activeClassName="active"
        >
          Inicio
        </Link>
        <Link
          className="hover:text-red-700 font-medium"
          to="/servicos"
          activeClassName="active"
        >
          Serviços
        </Link>
        <Link
          className="hover:text-red-700 font-medium"
          to="/menu"
          activeClassName="active"
        >
          Menu
        </Link>
        <Link
          className="hover:text-red-700 font-medium"
          to="/contactos"
          activeClassName="active"
        >
          Contactos
        </Link>
        <Link to="/recomchefe" activeClassName="active">
          <button className="flex items-center font-medium shadow-md w-full h-8 px-2 text-gray-700 bg-yellow-500 rounded-sm active:text-red-700">
            <span className="text-xs">Sugestão do Chefe</span>
            <FaArrowAltCircleRight className="w-4 h-4 ml-3 fill-current" />
          </button>
        </Link>
      </div>
    </aside>
  )
}

export default sidebar
