import React from "react"
import { Link } from "gatsby"
import { HiOutlineMenu } from "react-icons/hi"
import { FaArrowAltCircleRight } from "react-icons/fa"
import LogoBlack from "../images/logo-black.png"

const Navbar = ({ toggleSidebar }) => {
  return (
    <div className=" relative w-full h-16 bg-white flex justify-center items-center ">
      <nav className="w-full mx-auto fixed bg-white z-50  lg:mt-12 lg:absolute  ">
        <div className="w-4/5 mx-auto flex justify-between items-center">
          <Link
            to="/"
            className="w-16 h-16 lg:w-20 lg:h-20 cursor-pointer  lg:mt-4  "
          >
            <img
              src={LogoBlack}
              alt="Restaurante Monte Alegre"
              className="relative top-0.5 lg:bottom-3 lg:hidden"
              id="home"
            />
            <img
              src={LogoBlack}
              alt="Restaurante Monte Alegre"
              className="relative lg:bottom-3 hidden lg:block"
              id="home"
            />
          </Link>
          <div className=" hidden text-white lg:flex  lg:text-gray-500 lg:relative lg:left-10 lg:gap-10">
            <Link
              to="/"
              className="font-medium tracking-wide lg:text-lg hover:text-red-700"
              activeClassName="active"
            >
              Inicio
            </Link>
            <Link
              to="/servicos"
              className=" font-medium tracking-wide lg:text-lg hover:text-red-700"
              activeClassName="active"
            >
              Serviços
            </Link>
            <Link
              to="/menu"
              className=" font-medium tracking-wide lg:text-lg hover:text-red-700"
              activeClassName="active"
            >
              Menu
            </Link>
            <Link
              to="/contactos"
              className=" font-medium tracking-wide lg:text-lg hover:text-red-700"
              activeClassName="active"
            >
              Contactos
            </Link>
          </div>
          <button
            onClick={toggleSidebar}
            className=" lg:hidden toggleBtn relative left-1/2  mr-24"
          >
            <HiOutlineMenu className="text-black hover:text-yellow-500" />
          </button>
          <Link to="/#recomendacao">
            <button className="hidden lg:inline-flex lg:items-center lg:font-medium lg:shadow-md lg:h-7 lg:px-3  lg:text-gray-700 lg:transition-colors lg:duration-150 lg:bg-yellow-500 lg:rounded-sm lg:focus:shadow-outline lg:hover:bg-yellow-600">
              <span className="text-xs">Sugestão do Chefe</span>
              <FaArrowAltCircleRight className="w-3 h-4 ml-3 fill-current" />
            </button>
          </Link>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
